import React, { useContext } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { BrinkContext } from "../components/context/BrinkContext"
import { containerMaxWidth, MEDIA_MIN_MEDIUM } from "../constants"
import Layout from "../components/Layout"
import SecureLayout from "../components/SecureLayout"
import WidgetLoader from "../components/widgets/WidgetLoader"
import Breadcrumbs from "../components/ui/Breadcrumbs"

const Container = styled.div`
  max-width: ${containerMaxWidth};
  margin: 0 auto;
  padding: 0 2.5rem 8rem;
  text-align: center;

  iframe {
    width: 100%;
    min-height: 38rem;
  }
`

const IframWrapper = styled.div`
  border: 0.1rem solid ${(p) => p.theme.colors.lightBorder};
  max-width: 60rem;
  margin: 0 auto;
`

const Title = styled.h1`
  margin-top: 3rem;
  padding: 0 3rem;
  text-align: center;
  font-size: 2.4rem;
  line-height: 3rem;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 3.2rem;
  }
`

const ReturnPage = ({ data: { sanityReturnPage }, pageContext }) => {
  const { languageCode } = useContext(BrinkContext)
  const { pageTitle, enableReclaimitIframe, widgets } = sanityReturnPage || {}
  const { loginEnabled } = pageContext.sanityLoginPage
  const LayoutComponent = loginEnabled ? SecureLayout : Layout
  const title = pageTitle[languageCode] || pageTitle.en

  return (
    <LayoutComponent
      meta={{ title: title }}
      invertedHeader
      pageContext={pageContext}
    >
      <Breadcrumbs
        breadcrumbs={[
          {
            slug: "/returns-and-exchanges",
            name: title
          }
        ]}
      />
      <Container>
        <Title>{title}</Title>
        {widgets?.length && <WidgetLoader widgets={widgets} />}
        {enableReclaimitIframe && (
          <IframWrapper>
            <iframe
              title={title}
              id="reclaimitiframe"
              frameborder="0"
              scrolling="auto"
              marginheight="0"
              marginwidth="0"
              src="https://reclaimit.cestnormal.co/customer/en-US/cestnormal/reclaim/ConsumerLogin?key=7FDB9A7A-4DF3-4505-881B-FF91BD148822"
            ></iframe>
          </IframWrapper>
        )}
      </Container>
    </LayoutComponent>
  )
}

export default ReturnPage

export const query = graphql`
  query {
    sanityReturnPage {
      pageTitle {
        en
      }
      enableReclaimitIframe
      widgets {
        ...TextWidget
      }
    }
  }
`
